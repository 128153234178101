import React, { useState } from 'react';
import apiClient from '../utils/api';
import { Button, Typography, List, ListItem, ListItemText } from '@mui/material';

function ImageTranslationForm() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [translation, setTranslation] = useState('');
  const [newWords, setNewWords] = useState([]);
  const [extractedText, setExtractedText] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleTranslate = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await apiClient.post('/translate/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setTranslation(response.data.translation);
      setNewWords(response.data.new_words);
      setExtractedText(response.data.extracted_text);
    } catch (error) {
      console.error('Error translating image:', error);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <Button
        variant="contained"
        color="primary"
        onClick={handleTranslate}
        style={{ marginTop: '10px' }}
        disabled={!selectedFile}
      >
        Translate Image
      </Button>
      {extractedText && (
        <div>
          <Typography variant="h6" style={{ marginTop: '20px' }}>
            Extracted Text:
          </Typography>
          <Typography variant="body1">{extractedText}</Typography>
        </div>
      )}
      {translation && (
        <div>
          <Typography variant="h6" style={{ marginTop: '20px' }}>
            English Translation:
          </Typography>
          <Typography variant="body1">{translation}</Typography>
        </div>
      )}
      {newWords.length > 0 && (
        <div>
          <Typography variant="h6" style={{ marginTop: '20px' }}>
            New Latvian Words:
          </Typography>
          <List>
            {newWords.map((wordWithTranslation) => (
              <ListItem key={wordWithTranslation}>
                <ListItemText primary={wordWithTranslation} />
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}

export default ImageTranslationForm;
