import React, { useState } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import apiClient from '../utils/api';
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

function VoiceTranslationForm() {
  const [latvianText, setLatvianText] = useState('');
  const [englishTranslation, setEnglishTranslation] = useState('');
  const [newWordsWithTranslations, setNewWordsWithTranslations] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleStop = async (blobUrl, blob) => {
    setIsUploading(true);
    setErrorMessage('');

    const formData = new FormData();
    formData.append('file', blob, 'audio.webm');

    try {
      const response = await apiClient.post('/translate/transcribe/',
        formData
      );

      setLatvianText(response.data.latvian_text);
      setEnglishTranslation(response.data.english_translation);
      setNewWordsWithTranslations(
        response.data.new_words_with_translations || []
      );
    } catch (error) {
      console.error('Error uploading audio:', error);
      setErrorMessage('An error occurred during transcription. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <ReactMediaRecorder
      audio
      onStop={handleStop}
      render={({ status, startRecording, stopRecording }) => (
        <div>
          <Typography variant="h6">Voice Translation</Typography>
          {/* <Typography variant="body1">Status: {status}</Typography> */}
          <Button
            variant="contained"
            color="primary"
            onClick={startRecording}
            disabled={status === 'recording'}
            style={{ marginRight: '10px', marginTop: '10px' }}
          >
            Start Recording
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={stopRecording}
            disabled={status !== 'recording'}
            style={{ marginTop: '10px' }}
          >
            Stop Recording
          </Button>
          {isUploading && (
            <Typography variant="body1" style={{ marginTop: '10px' }}>
              Processing transcription...
            </Typography>
          )}
          {errorMessage && (
            <Typography
              variant="body1"
              color="error"
              style={{ marginTop: '10px' }}
            >
              {errorMessage}
            </Typography>
          )}
          {latvianText && (
            <div>
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                Latvian Text:
              </Typography>
              <Typography variant="body1">{latvianText}</Typography>
            </div>
          )}
          {englishTranslation && (
            <div>
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                English Translation:
              </Typography>
              <Typography variant="body1">{englishTranslation}</Typography>
            </div>
          )}
          {newWordsWithTranslations.length > 0 && (
            <div>
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                New Words with Translations:
              </Typography>
              <List>
                {newWordsWithTranslations.map((wordPair, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={wordPair}
                    />
                  </ListItem>
                ))}
              </List>
              <Typography
                variant="body2"
                color="primary"
                style={{ marginTop: '10px' }}
              >
                These words have been added to your vocabulary.
              </Typography>
            </div>
          )}
        </div>
      )}
    />
  );
}

export default VoiceTranslationForm;
