// src/components/TranslationForm.js

import React, { useState } from 'react';
import { TextField, Button, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Clear } from '@mui/icons-material';
import apiClient from '../utils/api';

function TranslationForm() {
  const [latvianText, setLatvianText] = useState('');
  const [englishTranslation, setEnglishTranslation] = useState('');
  const [newWords, setNewWords] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleTranslate = async () => {
    if (!latvianText.trim()) {
      setErrorMessage('Please enter some text to translate.');
      return;
    }

    try {
      const response = await apiClient.post('/translate/text/', {
        text: latvianText,
      });
      setEnglishTranslation(response.data.translation);
      setNewWords(response.data.new_words);
      setErrorMessage('');
    } catch (error) {
      console.error('Error translating text:', error);
      setErrorMessage('An error occurred during translation. Please try again.');
    }
  };

  const handleClear = () => {
    setLatvianText('');
    setEnglishTranslation('');
    setNewWords([]);
    setErrorMessage('');
  };

  return (
    <div>
      <TextField
        label="Latvian Text"
        variant="outlined"
        value={latvianText}
        onChange={(e) => setLatvianText(e.target.value)}
        fullWidth
        multiline
        minRows={4}
        maxRows={10}
        style={{ marginBottom: '10px' }}
      />
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={handleTranslate} style={{ marginTop: '10px' }}>
          Translate
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClear}
          startIcon={<Clear />}
          style={{ marginTop: '10px' }}
        >
          Clear
        </Button>
      </div>
      {errorMessage && (
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      )}
      {englishTranslation && (
        <div>
          <Typography variant="h6" style={{ marginTop: '20px' }}>
            English Translation:
          </Typography>
          <Typography variant="body1">{englishTranslation}</Typography>
        </div>
      )}
      {newWords.length > 0 && (
        <div>
          <Typography variant="h6" style={{ marginTop: '20px' }}>
            New Latvian Words:
          </Typography>
          <List>
            {newWords.map((word) => (
              <ListItem key={word}>
                <ListItemText primary={word} />
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}

export default TranslationForm;
