// src/components/VocabularyTable.js

import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  TextField,
  InputAdornment,
  TablePagination,
  Tooltip,
  FormControlLabel,
  Switch,
  Paper,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { Search, ReportProblemOutlined, ReportProblem, GetApp, Flaky } from '@mui/icons-material';
import apiClient from '../utils/api';

function VocabularyTable() {
  const [vocabulary, setVocabulary] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('datetime_added');
  const [searchText, setSearchText] = useState('');
  const [showMarked, setShowMarked] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isSimplifying, setIsSimplifying] = useState(false);
  const [simplifyMessage, setSimplifyMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    fetchVocabulary();
  }, []);

  const handleDownload = () => {
    window.location.href = `${apiClient.defaults.baseURL}/download-vocabulary/`;
  };

  const handleSimplify = async () => {
    setIsSimplifying(true);
    try {
      const response = await apiClient.post('/simplify');
      setSimplifyMessage(response.data.message || 'Simplification process started.');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error starting simplification process:', error);
      setSimplifyMessage('Failed to start simplification process.');
      setOpenSnackbar(true);
    } finally {
      setIsSimplifying(false);
    }
  };

  const fetchVocabulary = async () => {
    try {
      const response = await apiClient.get('/vocabulary/');
      setVocabulary(response.data);
    } catch (error) {
      console.error('Error fetching vocabulary:', error);
      setError('Failed to load vocabulary.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMarkChange = async (id, mark) => {
    try {
      await apiClient.patch(`/vocabulary/${id}/mark`, null, {
        params: { mark },
      });
      // Refresh the vocabulary list
      fetchVocabulary();
    } catch (error) {
      console.error('Error updating mark status:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowMarkedChange = (event) => {
    setShowMarked(event.target.checked);
  };

  // Filter and sort the vocabulary list
  const filteredVocabulary = vocabulary.filter((item) => {
    const searchLower = searchText.toLowerCase();
    const latvianWordMatch = item.latvian_word.toLowerCase().includes(searchLower);
    const englishWordMatch = item.english_translation.toLowerCase().includes(searchLower);
    const markMatch = showMarked ? item.mark : !item.mark;
    return (latvianWordMatch || englishWordMatch) && markMatch;
  });

  const sortedVocabulary = stableSort(filteredVocabulary, getComparator(order, orderBy));

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedVocabulary.length - page * rowsPerPage);

  return (
    <div style={{ marginTop: '30px' }}>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        style={{ marginBottom: '10px', marginRight: '10px' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        style={{ marginTop: '10px' }}
        control={
          <Switch
            checked={showMarked}
            onChange={handleShowMarkedChange}
            name="showMarked"
            color="primary"
          />
        }
        label="Show Marked Words"
      />
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {['latvian_word', 'english_translation', 'datetime_added', 'mark'].map((headCell) => (
                  <TableCell key={headCell} style={{ fontWeight: 'bold' }}>
                    <TableSortLabel
                      active={orderBy === headCell}
                      direction={orderBy === headCell ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell)}
                    >
                      {formatHeader(headCell)}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedVocabulary
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                    }}
                  >
                    <TableCell>{row.latvian_word}</TableCell>
                    <TableCell>{row.english_translation}</TableCell>
                    <TableCell>{format(new Date(row.datetime_added), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell>
                      <Tooltip title={row.mark ? 'Unmark this word' : 'Mark this word'}>
                        <Checkbox
                          checked={row.mark}
                          onChange={() => handleMarkChange(row.id, !row.mark)}
                          icon={<ReportProblemOutlined />}
                          checkedIcon={<ReportProblem style={{ color: 'orange' }} />}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={sortedVocabulary.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Rows per page:"
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'left' }}>
        <Button
          variant="outlined"
          startIcon={<GetApp />}
          onClick={handleDownload}
          style={{
            opacity: 0.1,
            marginRight: '20px',
          }}
        >
          Download
        </Button>
        <Button
          variant="outlined"
          startIcon={<Flaky />}
          onClick={handleSimplify}
          disabled={isSimplifying}
          style={{
            opacity: 0.1,
            marginRight: '20px',
          }}
        >
          Simplify
        </Button>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={simplifyMessage.includes('Failed') ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {simplifyMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

// Helper functions for sorting
function descendingComparator(a, b, orderBy) {
  if (orderBy === 'datetime_added') {
    return new Date(b[orderBy]) - new Date(a[orderBy]);
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedArray = array.map((el, index) => [el, index]);
  stabilizedArray.sort((a, b) => {
    const orderRes = comparator(a[0], b[0]);
    if (orderRes !== 0) return orderRes;
    return a[1] - b[1];
  });
  return stabilizedArray.map((el) => el[0]);
}

function formatHeader(header) {
  switch (header) {
    case 'latvian_word':
      return 'Latvian Word';
    case 'english_translation':
      return 'English Translation';
    case 'datetime_added':
      return 'Date Added';
    case 'mark':
      return 'Mark';
    default:
      return header;
  }
}

export default VocabularyTable;
