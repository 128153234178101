// src/components/SimplifiedVocabularyTable.js

import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  InputAdornment,
  TablePagination,
  TableSortLabel,
  Button,
} from '@mui/material';
import { Search, GetApp } from '@mui/icons-material';
import apiClient from '../utils/api';

function SimplifiedVocabularyTable() {
  const [simplifiedWords, setSimplifiedWords] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('latvian_word');
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSimplifiedWords = async () => {
      try {
        const response = await apiClient.get('/simplified-vocabulary/');
        setSimplifiedWords(response.data);
      } catch (error) {
        console.error('Error fetching simplified vocabulary:', error);
        setError('Failed to load simplified vocabulary.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSimplifiedWords();
  }, []);

  const handleDownload = () => {
    window.location.href = `${apiClient.defaults.baseURL}/download-simplified-vocabulary/`;
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter and sort the simplified words
  const filteredSimplifiedWords = simplifiedWords.filter((word) => {
    const searchLower = searchText.toLowerCase();
    const latvianWordMatch = word.latvian_word.toLowerCase().includes(searchLower);
    const englishTranslationMatch = word.english_translation.toLowerCase().includes(searchLower);
    return latvianWordMatch || englishTranslationMatch;
  });

  const sortedSimplifiedWords = stableSort(filteredSimplifiedWords, getComparator(order, orderBy));

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, sortedSimplifiedWords.length - page * rowsPerPage);

  return (
    <div style={{ marginTop: '30px' }}>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        style={{ marginBottom: '10px', marginRight: '10px' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {['latvian_word', 'english_translation'].map((headCell) => (
                  <TableCell key={headCell} style={{ fontWeight: 'bold' }}>
                    <TableSortLabel
                      active={orderBy === headCell}
                      direction={orderBy === headCell ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell)}
                    >
                      {formatHeader(headCell)}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSimplifiedWords
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((word, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                    }}
                  >
                    <TableCell>{word.latvian_word}</TableCell>
                    <TableCell>{word.english_translation}</TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={sortedSimplifiedWords.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Rows per page:"
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'left' }}>
        <Button
          variant="outlined"
          startIcon={<GetApp />}
          onClick={handleDownload}
          style={{
            opacity: 0.1,
          }}
        >
          Download
        </Button>
      </div>
    </div>
  );
}

// Helper functions for sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedArray = array.map((el, index) => [el, index]);
  stabilizedArray.sort((a, b) => {
    const orderRes = comparator(a[0], b[0]);
    if (orderRes !== 0) return orderRes;
    return a[1] - b[1];
  });
  return stabilizedArray.map((el) => el[0]);
}

function formatHeader(header) {
  switch (header) {
    case 'latvian_word':
      return 'Latvian Word';
    case 'english_translation':
      return 'English Translation';
    default:
      return header;
  }
}

export default SimplifiedVocabularyTable;
